<template>
	<card-template>
		<template slot="titleCard">{{
			this.$subscriptions_locale.subTitle
		}}</template>

		<div class="row" v-if="formData.id">
			<div class="col-12 col-sm-6">
				<ul>
					<li>
						{{ this.$subscriptions_locale.columns.customerName }}:
						<b class="text-secondary">{{
							formData.customer.name
						}}</b>
					</li>
					<li>
						{{ this.$subscriptions_locale.columns.type }}:
						<b class="text-secondary">{{ formData.type.name }}</b>
					</li>
					<li>
						{{ this.$subscriptions_locale.columns.quantity }}:
						<b class="text-secondary">{{ formData.quantity }}</b>
					</li>
					<li>
						{{ this.$subscriptions_locale.columns.amount }}:
						<b class="text-secondary">{{ formData.amount }}</b>
					</li>
					<li>
						{{ this.$subscriptions_locale.columns.start_date }}:
						<b class="text-secondary">{{ formData.start_date }}</b>
					</li>
					<li v-if="formData.end_date">
						{{ this.$subscriptions_locale.columns.end_date }}:
						<b class="text-secondary">{{ formData.end_date }}</b>
					</li>
				</ul>
			</div>
			<div class="col-12 col-sm-6">
				<template v-for="item, index in formData.subscription_licences">
					<div class="col-12">
						<span v-if="item.devicelicence_id == null" class="p-2">
							<i class="fa fa-unlock mr-2 text-success"></i> Libre [Licencia {{ index+ 1}} de
							{{ formData.quantity }}]
						</span>
						<span v-if="item.devicelicence_id != null && item.applied_date == null" class="p-2">
							<i class="fa fa-lock mr-2 text-warning"></i> Asignada a
								<a class="white bg-dark pl-1 pr-1"
									:href="'/devicelicence/' + item.devicelicence.device.id + '/edit'">
									{{ item.devicelicence.device.name }}</a>
							 [Licencia {{ index+ 1}} de {{ formData.quantity }}]

						</span>
						<span v-if="item.devicelicence_id != null && item.applied_date != null" class="p-2">

							<i class="fa fa-lock mr-2 text-warning"></i>
							Asignada Y
							<i class="fa fa-star mr-2 text-secondary"></i>
							Activada en
							<a class="white bg-dark pl-1 pr-1" :href="'/devicelicence/' + item.devicelicence.device.id + '/edit'">
								{{ item.devicelicence.device.name }}</a>
							[Licencia {{ index+ 1}} de {{ formData.quantity }}]
						</span>
					</div>
				</template>
			</div>
		</div>
		<div class="row" v-if="!formData.id">
			<div class="col-12 col-md-3">
				<customers-combo @add="syncCustomer" :current="formData.customer" :multiple="false" :label="
					this.$subscriptions_locale.columns.customerName
				"></customers-combo>
			</div>
			<div class="col-12 col-md-3">
				<subscription-type-combo :current="formData.subscriptionType" @add="setSubscriptionType"
					:required="'required'" :label="
						this.$subscriptions_locale.columns.type
					"></subscription-type-combo>
			</div>
			<div class="col-12 col-md-3">
				<div class="mb-3">
					<label for="quantity" class="required">{{
						this.$subscriptions_locale.columns.quantity
					}}</label>
					<input type="number" v-model="formData.quantity" id="subscription_quantity" class="form-control" />
				</div>
			</div>
			<div class="col-12 col-md-3">
				<div class="mb-3">
					<label for="amount" class="required">{{
						this.$subscriptions_locale.columns.amount
					}}</label>
					<input type="number" v-model="formData.amount" id="subscription_amount" class="form-control" />
				</div>
			</div>
		</div>
		<div class="row" v-if="!formData.id">
			<div class="col-12 col-md-3">
				<div class="mb-3">
					<label for="start_date" class="required">{{
						this.$subscriptions_locale.columns.start_date
					}}</label>
					<input type="date" v-model="formData.start_date" id="subscription_start_date"
						class="form-control" />
				</div>
			</div>
			<div class="col-12 col-md-3">
				<div class="mb-3">
					<label for="end_date">{{
						this.$subscriptions_locale.columns.end_date
					}}</label>
					<input type="date" v-model="formData.end_date" id="subscription_end_date" class="form-control" />
				</div>
			</div>
		</div>
		<div class="row"></div>
		<hr />
		<button @click="save" class="btn btn-primary" v-if="!formData.id">
			{{ this.$subscriptions_locale.buttons.save }}
		</button>
	</card-template>
</template>

<script>
import SubscriptionTypeCombo from './SubscriptionTypeCombo'
import CustomersCombo from '/../Modules/Customers/Resources/assets/components/CustomerCombo'
import moment from 'moment'

export default {
	name: 'SubscriptionForm',
	components: {
		SubscriptionTypeCombo,
		CustomersCombo,
	},
	props: {
		subscription: { required: false },
	},
	created() {
		if (this.subscription) {
			this.formData._method = 'put'
			this.editPath = '/' + this.subscription.id
			this.getItem()
		}
	},
	data() {
		return {
			device: {
				code: '',
			},
			editPath: '',
			subscriptionType: null,
			customer: null,
			formData: {
				id: null,
				customer: null,
				amount: '',
				start_date: '',
				end_date: '',
				quantity: '',
				subscription_type: '',
				customer_id: '',
				type: null,
				_method: 'post',
			},
		}
	},
	methods: {
		getItem() {
			this.formData = this.subscription
			this.formData.id = this.subscription.id
			this.formData.customer = this.subscription.customer
				? this.subscription.customer
				: null
			this.formData.amount = this.subscription.amount
			this.formData.start_date = moment(
				this.subscription.start_date
			).format('YYYY-MM-DD')
			this.formData.end_date = this.subscription.end_date
				? moment(this.subscription.end_date).format('YYYY-MM-DD')
				: null
			this.formData.updated_at = this.subscription.updated_at
				? moment(this.subscription.updated_at).format('YYYY-MM-DD')
				: null

			this.formData.created_at = this.subscription.created_at
				? moment(this.subscription.created_at).format('YYYY-MM-DD')
				: null

			this.formData.subscription_type = this.subscription
				.subscription_type
				? this.subscription.subscription_type.id
				: null
			this.formData.quantity = this.subscription.quantity
			this.subscriptionType = this.subscription.subscription_type
				? this.subscription.subscription_type.id
				: null
		},
		setSubscriptionType: function (subscriptionType) {
			this.subscriptionType = subscriptionType
			this.formData.subscription_type = subscriptionType.id
		},
		syncCustomer(customer) {
			this.formData.customer = customer ? customer : null
			this.formData.customer_id = customer.id ? customer.id : null
		},
		save: function () {
			let formData = new FormData()
			Object.keys(this.formData).map((field) => {
				formData.append(
					field,
					this.formData[field] === null ? '' : this.formData[field]
				)
			})
			axios
				.post(`/api-admin/subscriptions${this.editPath}`, formData, {
					headers: { 'content-type': 'multipart/form-data' },
				})
				.then((response) => {
					this.$helpers.showLoading()
					this.$helpers.toastSuccess()
					setTimeout(() => {
						//window.location = `/subscriptions/${response.id}/edit`
						window.location = `/subscriptions/`
					}, 1000)
				})
		},
	},
}
</script>

<style scoped>

</style>
