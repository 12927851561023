<template>
	<card-template class="w-100">
		<div class="row ">
			<div class="col-8">
				<div class="row">
					<div class="col-12 col-md-4">
						<div class="mb-3 form-floating">
							<input
								id="name"
								v-model="formData.name"
								:readonly="this.show"
								type="text"
								class="form-control"
								:placeholder="this.$devices_locale.columns.name"
							>
							<label for="name" class="required">{{ this.$devices_locale.columns.name }}</label>
						</div>
					</div>
					<div class="col-12 col-md-4">
						<div class="mb-3 form-floating">
							<input
								id="name"
								v-model="formData.code"
								:readonly="this.show"
								type="text"
								class="form-control"
								:placeholder="this.$devices_locale.columns.code"
							>
							<label for="code">{{ this.$devices_locale.columns.code }}</label>
						</div>
					</div>
					<div class="col-12 col-md-4 mb-3 ">
						<device-type-combo
							v-if="!this.show"
							:current="deviceType"
							:required="'required'"
							:label="this.$devices_locale.columns.type"
							@add="setDeviceType"
						></device-type-combo>
						<span v-else>{{ this.$devices_locale.columns.type }}: {{ this.deviceType?this.deviceType.name:' - ' }}</span>
					</div>
					<div class="col-12 col-md-4 mb-3 ">
						<zone-combo
							v-if="!this.show"
							:current="zone"
							:required="'required'"
							:label="this.$devices_locale.columns.zone"
							@add="setZone"
						></zone-combo>
						<span
							v-else
						>{{ this.$devices_locale.columns.zone }}: {{ this.zone?this.zone.name:' - ' }}</span>
					</div>
					<div class="col-12 col-md-4 mb-3 ">
						<device-combo
							v-if="!this.show"
							:current="parent_device"
							:label="this.$devices_locale.columns.device"
							@add="setDevice"
						></device-combo>
						<span v-else>{{ this.$devices_locale.columns.device }}: {{ this.parent_device?this.parent_device.name:' - ' }}</span>
					</div>
					<div v-if="this.device!=null" class="col-12 col-md-4 mb-3 form-floating">
						<input
							v-if="!this.show"
							id="last_lifebit"
							v-model="this.device.last_lifebit"
							type="text"
							readonly="readonly"
							class="form-control"
							:placeholder="this.$devices_locale.columns.last_lifebit"
						>
						<span v-else class="w-100">{{ this.device.last_lifebit }}</span>
						<label for="last_lifebit">{{ this.$devices_locale.columns.last_lifebit }}</label>
					</div>
				</div>
				<div class="row">
					<div class="col-sm-12 mb-3 form-floating">
						<textarea
							id="descriptionDevice"
							v-model="formData.description"
							style="height: 200px;"
							:readonly="this.show"
							class="form-control"
							:placeholder="this.$devices_locale.columns.description"
						></textarea>
						<label for="descriptionDevice" class="">{{
							this.$devices_locale.columns.description
						}}</label>
					</div>
				</div>
			</div>
			<div class="col-4">
				<map-device
					v-if="this.zone"
					:device_id="this.formData.id"
					position_by="latLng"
					:show="this.show"
					:zone="this.zone"
					@markerChanged="positionDeviceChange"
				/>
				<span v-else><i
					class="fa fa-info-circle mr-2"
				></i> Para ver el mapa, añada el dispositivo a una zona.</span>
			</div>
		</div>
		<device-configuration
			v-if="this.device&&this.formData.is_controller"
			ref="deviceConfiguration"
			:device="this.device"
			:device_date="this.device.datetime_config"
			:device_signal_time="this.device_signal_time"
			:show="show"
			:frequency="this.device.frequency"
			:diary_config="this.device.diary_config"
			:device_outputs="this.device.outputs_config"
			@frequencyChanged="setFrequency"
			@diaryChanged="setDiary"
			@setIrrigationTable="setIrrigationTable"
		></device-configuration>
		<hr>
		<button class="btn btn-primary" @click="save">
			{{ this.$devices_locale.buttons.save }}
		</button>
		<button
			v-if="formData.id!==null"
			class="btn btn-excel float float-right btn-primary"
			style="background-color: #182e5e;color:white;"
			@click="goTo(formData.id)"
		>
			<i class="fa fa-key mr-1"></i>Generar Licencia
		</button>
	</card-template>
</template>

<script>
import DeviceTypeCombo from './DeviceTypeCombo';
import DeviceCombo from './DeviceCombo';
import ZoneCombo from './../zones/ZoneCombo';
import MapDevice from './MapDevice';
import DeviceConfiguration from './device-config/DeviceConfiguration';

export default {
	name: 'DeviceForm',
	components: {
		DeviceTypeCombo,
		DeviceCombo,
		ZoneCombo,
		MapDevice,
		DeviceConfiguration
	},
	props: {
		device: {required: false},
		show: {default: false}
	},
	created() {
		if (this.device) {
			this.formData._method = 'put';
			this.editPath = '/' + this.device.id;
			this.getItem();
		}
	},
	computed: {
		getTypePos: function () {
			if (!this.formData.lat) {
				return 'latLng';
			}
			return 'checkpoints';
		}
	},
	data() {
		return {
			editPath: '',
			deviceType: null,
			parent_device: null,
			zone: null,
			device_signal_time: null,
			formData: {
				id: null,
				name: '',
				code: '',
				description: '',
				device: '',
				zone: '',
				unit: '',
				device_type: '',
				frequency: 1,
				diary_config: [],
				datetime_config: '',
				outputs_config: '',
				change_date: '',
				irrigation_table: '',
				is_controller: 0,
				lat: 0,
				lng: 0,
				_method: 'post'
			}
		};
	},
	methods: {
		positionDeviceChange(position) {
			this.formData.lat = position.lat;
			this.formData.lng = position.lng;
		},
		getConfigurationDevice() {
			this.formData.datetime_config = this.$refs.deviceConfiguration.current_device_date;
			this.formData.change_date = this.$refs.deviceConfiguration.change_date;
			this.formData.outputs_config = JSON.stringify(this.$refs.deviceConfiguration.$refs.changeOutputDevice.outputs);
		},
		save() {
			if (this.formData.is_controller&&this.$refs.deviceConfiguration) {
				this.getConfigurationDevice();
			}

			let formData = new FormData();
			Object.keys(this.formData).map(field => {
				formData.append(field, this.formData[field] === null ? '' : this.formData[field]);
			});
			axios.post(`/api-admin/devices${this.editPath}`, formData, {headers: {'content-type': 'multipart/form-data'}}).then(response => {
				this.$helpers.showLoading();
				this.$helpers.toastSuccess();
				setTimeout(() => {
					window.location = '/devices';
				}, 1000);
			}).catch(error => {
				setTimeout(() => {
					// window.location = `/devices/${this.device.id}/edit`
				}, 1000);
			});
		},
		goTo(id){
			window.location = `/devicelicence/${id}/edit`;
		},
		getItem() {
			this.formData.name = this.device.name;
			this.formData.code = this.device.code;
			this.formData.id = this.device.id;
			this.formData.description = this.device.description;
			this.formData.device_type = this.device.device_type_id;
			this.formData.zone = this.device.zone_id;
			this.formData.device = this.device.device_id;
			this.formData.diary_config = this.device.diary_config;
			this.formData.is_controller = this.device.is_controller;
			this.formData.outputs_config = this.device.outputs_config;
			this.formData.irrigation_table = this.device.irrigation_table;
			this.formData.lat = this.device.lat;
			this.formData.lng = this.device.lng;
			this.device.frequency != null ? this.formData.frequency = this.device.frequency : this.formData.frequency;
			this.parent_device = this.device.device;
			this.deviceType = this.device.device_type;
			this.zone = this.device.zone;
			this.device_signal_time = this.getDeviceHour(this.device.last_signal_values);
		},
		setDevice(device) {
			this.formData.device = device.id??null;
		},
		setDeviceType(deviceType) {
			this.deviceType = deviceType;
			this.formData.device_type = deviceType.id;
			this.checkIfDeviceController(deviceType);
		},
		checkIfDeviceController(deviceType) {
			this.formData.is_controller = 0;
			if (deviceType.code == 'controller') {
				this.formData.is_controller = 1;
			}
		},
		setFrequency(frequency) {
			this.formData.frequency = frequency;
		},

		setIrrigationTable(irrigation_table) {
			this.formData.irrigation_table = JSON.stringify(irrigation_table);
		},
		setDiary(diary) {
			this.formData.diary_config = JSON.stringify(diary);
		},
		setZone(zone) {

			if (zone && zone.id){
				this.formData.zone = zone.id;
				this.formData.lat = zone.lat;
				this.formData.lng = zone.lng;
				axios.get(`/api-admin/zones/map/zone/${zone.id}`).then(res => {

					delete res.data.checkpoints;
					delete res.data.devices;
					this.zone = res.data;
					this.zone.checkpoints=null;
					this.zone.devices=null;
				});
			} else {
				this.formData.zone = null;
				this.zone = {};
			}

		},
		getDeviceHour(signals) {
			if (signals){
				let signalsJson = JSON.parse(signals);
				let hour, minute, seconds;
				Object.keys(signalsJson).forEach((index) => {
					index== 'hour' ? hour = signalsJson[index] : '';
					index== 'minute' ? minute = signalsJson[index] : '';
					index == 'second' ? seconds = signalsJson[index] : '';
				});
				return `${hour}:${minute}:${seconds}`;
			}
		}

	}
};
</script>

<style scoped>

</style>
