<template>
	<card-template>
		<template slot="titleCard">
			{{ this.$subscriptions_locale.nav }}
		</template>
		<div class="table-responsive">
			<inno-core-datatable
				:enable-create-btn="this.$helpers.hasPermission('subscriptions.create')"
				:enable-edit-btn="this.$helpers.hasPermission('subscriptions.update')"
				:enable-delete-btn="this.$helpers.hasPermission('subscriptions.delete')"
				:id-table="idTable"
				:columns-data="columns"
				:row-items="items"
				:custom-fields="customFields"
				@edit="editItem"
				@delete="deleteItem"
				@new="newItem"
			/>
		</div>
		<modal-confirm-delete @confirmed="removeItem" />
	</card-template>
</template>

<script>

export default {
	name: 'SubscriptionList',
	created() {
		this.getItems();
	},
	computed: {},
	data() {
		return {
			idTable: 'subscriptionsTable',
			items: [],
			customFields: [],
			columns: [
				{
					field: 'id',
					label: '#',
					display: 'min_tabletP'
				},
				{
					field: 'customer.name',
					label: this.$subscriptions_locale.columns.customerName
				},
				{
					field: 'amount',
					label: this.$subscriptions_locale.columns.amount
				},
				{
					field: 'start_date',
					label: this.$subscriptions_locale.columns.start_date
				},
				{
					field: 'end_date',
					label: this.$subscriptions_locale.columns.end_date
				},
				{
					field: 'quantity',
					label: this.$subscriptions_locale.columns.quantity
				},
				{
					field: 'type.name',
					label: this.$subscriptions_locale.columns.type
				},
				{
					field: 'options',
					label: this.$subscriptions_locale.columns.options
				}
			]
		};
	},
	methods: {
		getItems() {
			axios.get('/api-admin/subscriptions').then(response => {
				const rows = response.data;
				this.items = rows;
			});
		},
		editItem(item) {
			window.location = `/subscriptions/${item.id}/edit`;
			//this.$toast.error(this.$t('Forbidden'))
		},
		deleteItem(item) {
			this.$bus.$emit('fireModalConfirmDelete', {
				text: `${this.$subscriptions_locale.modals.delete_subscription} ${item.name} `,
				parameters: item
			});
		},
		removeItem(item) {
			axios.delete(`/api-admin/subscriptions/${item.id}`).then(response => {
				this.$toast.success(this.$t('toast.delete_success'));
				this.getItems();
			});
		},
		newItem() {
			window.location = '/subscriptions/create';
		}
	}

};
</script>
